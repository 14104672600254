import { default as DashboardFRyWLc9I27Meta } from "/usr/src/app/pages/Dashboard.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
export default [
  {
    name: DashboardFRyWLc9I27Meta?.name ?? "Dashboard",
    path: DashboardFRyWLc9I27Meta?.path ?? "/Dashboard",
    meta: DashboardFRyWLc9I27Meta || {},
    alias: DashboardFRyWLc9I27Meta?.alias || [],
    redirect: DashboardFRyWLc9I27Meta?.redirect,
    component: () => import("/usr/src/app/pages/Dashboard.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  }
]